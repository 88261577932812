import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle, SecondaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseContent from "src/components/Base/BaseContent"
import BaseIcon from "src/components/Base/BaseIcon"
import SocialMediaIcons from "src/components/SocialMediaIcons"
import BlogPostList from "src/components/BlogPostList"
import BlogPostAuthor from "src/components/BlogPostAuthor"
import { GatsbyImage } from "gatsby-plugin-image"
import { get } from "lodash"
import { slugify } from "src/utils/helpers"

const BlogPost = (props) => {
  const {
    title,
    subtitle,
    lead,
    slug,
    author,
    date,
    cover_image,
    content,
    footer,
  } = props.pageContext

  const [observed, setObserved] = useState()

  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allStrapiBlogPosts(
          limit: 4
          filter: { publish: { eq: true } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            title
            slug
            date(formatString: "MMMM D, YYYY")
            cover_image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 289
                    height: 192
                    quality: 80
                    layout: FIXED
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  const leadNaked = (lead && lead.replace(/<[^>]*>?/gm, "")) || null // remove html
  const postUrl = `${data.site.siteMetadata.siteUrl}/blog/${slug}`
  const socialMediaItems = [
    {
      title: "twitter",
      url: `http://twitter.com/share?text=${title}&url=${postUrl}`,
    },
    {
      title: "facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`,
    },
    {
      title: "linkedin",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${postUrl}`,
    },
  ]
  const morePosts = data.allStrapiBlogPosts.nodes
    .filter((post) => {
      return post.slug !== slug
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 3)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <Wrapper className={observed ? "-observed" : ""}>
      <Seo
        title={title}
        description={leadNaked || subtitle}
        image={
          !!cover_image
            ? get(
                cover_image,
                "localFile.childImageSharp.gatsbyImageData.src"
              ) || cover_image.localFile.publicURL
            : null
        }
      />
      <Container narrow>
        <Meta>
          {author && (
            <MetaAuthor to={`/blog/authors/${slugify(author.Name)}`}>
              <MetaAuthorPhotoWrapper>
                {author.picture ? (
                  author.picture.localFile.childImageSharp ? (
                    <MetaAuthorPhotoSharp
                      image={get(
                        author,
                        "picture.localFile.childImageSharp.gatsbyImageData"
                      )}
                      alt={author.Name + ' photo'}
                    />
                  ) : (
                    <MetaAuthorPhoto
                      src={author.picture.localFile.publicURL}
                      alt={author.Name}
                    />
                  )
                ) : (
                  <MetaNoAuthorIcon name="ok-bird" />
                )}
              </MetaAuthorPhotoWrapper>
              <MetaAuthorName>{author.Name}</MetaAuthorName>
            </MetaAuthor>
          )}
          {date !== 'January 1, 1970' &&
            <MetaDate>{date}</MetaDate>
          }
        </Meta>
        <SocialMedia media={socialMediaItems} />
        <Title as="h1">{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {lead && <Lead dangerouslySetInnerHTML={{ __html: lead }} />}
        {content && <BlogPostContent dangerouslySetInnerHTML={{ __html: content }} />}
        {footer && <Footer dangerouslySetInnerHTML={{ __html: footer }} />}
        {author && (
          <BlogPostAuthor
            name={author.Name}
            bio={author.description_to_be_shown_next_to_their_blog_posts}
            imageUrl={get(author, "picture.localFile.publicURL")}
            imageSharp={get(author, "picture.localFile.childImageSharp")}
            twitterUsername={author.twitter_handle}
            path={`/blog/authors/${slugify(author.Name)}`}
          />
        )}
      </Container>
      <MorePosts>
        <MorePostTitle>More from our blog</MorePostTitle>
        <BlogPostList posts={morePosts} />
      </MorePosts>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 150px;
  padding-top: 180px;

  ${media.tablet} {
    margin-bottom: 200px;
    padding-top: 250px;
  }
`

const Container = styled(BaseContainer)`
  margin-bottom: 150px;
  position: relative;
  font-size: 18px;
  line-height: 1.5;
  font-family: ${(p) => p.theme.font.tertiary};
  color: ${(p) => p.theme.color.textDark};

  > :nth-child(2),
  > :nth-child(3),
  > :nth-child(4),
  > :nth-child(5),
  > :nth-child(6),
  > :nth-child(7) {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    ${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.9s;
  }

  > :nth-child(3) {
    /* transition-delay: .45s; */
  }

  > :nth-child(4) {
    transition-delay: 0.6s;
  }

  > :nth-child(5) {
    transition-delay: 0.75s;
  }

  > :nth-child(6) {
    transition-delay: 0.9s;
  }

  > :nth-child(7) {
    transition-delay: 1.05s;
  }

  a {
    &:not([class*="MetaAuthor"]):not([class*="BlogPostAuthor"]) {
      text-decoration: underline;
      /* border-bottom: 1px solid #b4cbc7; */
      transition: 0.15s;

      &:hover {
        /* border-color: ${(p) => p.theme.color.primary}; */
        /* color: ${(p) => p.theme.color.primary}; */
        color: #17baaa;
      }
    }
  }

  sup {
    margin-left: 5px;
    font-weight: bold;
    font-size: 13px;
    vertical-align: super;
    font-family: ${(p) => p.theme.font.mono};
    color: ${(p) => p.theme.color.textLight};

    a {
      text-decoration: none;
    }
  }

  ${media.tablet} {
    margin-bottom: 205px;
    font-size: 21px;
  }
`

const Meta = styled.div`
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 1;
  font-family: ${(p) => p.theme.font.primary};
  color: ${(p) => p.theme.color.textLight};

  > * {
    transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

    div${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.3s;
  }

  ${media.tablet} {
    display: flex;
    align-items: center;
    margin-bottom: 75px;
  }
`

const MetaAuthor = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
`

const MetaAuthorPhotoWrapper = styled.span`
  height: 42px;
  margin-right: 12px;
  padding: 2px;
  position: relative;
  background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 50%;
  /* box-shadow: 0 16px 16px rgba(3, 34, 33, 0.08); */

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 4px solid #fff;
    border-radius: 50%;
  }
`

const MetaAuthorPhoto = styled.img`
  width: 38px;
  height: 38px;
  border: 4px solid #fff;
  border-radius: 50%;
`

const MetaAuthorPhotoSharp = styled(GatsbyImage)`
  width: 38px !important;
  height: 38px !important;
  border: 4px solid #fff;
  border-radius: 50%;

  &,
  img {
    border-radius: 50%;
  }
`

const MetaNoAuthorIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  margin: 11px;
  color: #fff;
`

const MetaAuthorName = styled.span`
  font-weight: 500;
  color: #000;
`

const MetaDate = styled.p`
  /* margin-top: 10px; */
  font-size: 13px;

  &:nth-child(2) {
    margin-left: 56px;
  }

  ${media.tablet} {
    margin-top: 0;
    font-size: inherit;

    &:nth-child(2) {
      margin-left: 30px;
    }
  }
`

const SocialMedia = styled(SocialMediaIcons)`
  display: none;
  position: absolute;
  top: -4px;
  right: ${(p) => p.theme.sidePadding.tablet - 10}px;

  ${media.tablet} {
    display: block;
  }

  ${media.desktop} {
    flex-direction: column;
    top: -4px;
    right: -150px;

    li {
      margin: 0 0 30px;
    }
  }
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 60px;
  font-family: ${(p) => p.theme.font.primary};

  ${media.tablet} {
    max-width: 80%;
    margin-bottom: 90px;
  }

  ${media.desktop} {
    max-width: none;
  }
`

const Subtitle = styled.h2`
  /* display: none; */
  margin-bottom: 90px;
  font-size: ${(p) => p.theme.baseFontSize.desktop};
  line-height: ${(p) => p.theme.baseLineHeight};
  font-family: ${(p) => p.theme.font.primary};
  color: ${(p) => p.theme.color.text};

  ${Title} ~ & {
    margin-top: -50px;
  }

  ${media.tablet} {
    ${Title} ~ & {
      margin-top: -70px;
    }
  }
`

const Lead = styled.p`
  /* display: none; */
  margin-bottom: 60px;
  font-weight: 500;
  font-size: inherit;
  line-height: ${(p) => p.theme.baseLineHeight};
  font-family: ${(p) => p.theme.font.primary};
  color: #000;

  ${Title} ~ & {
    margin-top: -30px;
  }

  ${media.tablet} {
    font-size: 21px;
  }
`

const BlogPostContent = styled(BaseContent)``

const Footer = styled.div`
  /* display: none; */
  margin-top: 60px;
  margin-bottom: -30px;
  font-size: 13px;
  line-height: ${(p) => p.theme.baseLineHeight};
  font-family: ${(p) => p.theme.font.primary};
  color: ${(p) => p.theme.color.textLight};

  ol {
    margin-left: 30px;
    list-style: decimal;
  }
`

const MorePosts = styled(BaseContainer)``

const MorePostTitle = styled(SecondaryTitle)`
  margin-bottom: 50px;
  /* text-align: center; */
`

export default BlogPost
